<mat-radio-group [(ngModel)]="mode">
  <mat-radio-button value="0">
    Submit from my personal account
  </mat-radio-button>
  @if (auth.getAdministratedCompany()) {
    <mat-radio-button value="1">
      Submit the app on behalf of @if (userCompanies.length === 1) {
      <strong>{{auth.getAdministratedCompany()?.name}}</strong>
    }
    @if (userCompanies.length > 1) {
      <ng-select
        [(ngModel)]="selectedCompany"
        [items]="userCompanies"
        [clearable]="false"
        [clearSearchOnAdd]="true"
        [closeOnSelect]="true"
        bindLabel="name"
        bindValue="id"
        placeholder="Choose company"
        class="company-chooser"
        required>
      </ng-select>
    }
  </mat-radio-button>
}
@if (!auth.getAdministratedCompany()) {
  <mat-radio-button value="2">
    Save and list a company to submit the {{ pd.enabled ? 'product': 'application' }} on its behalf
  </mat-radio-button>
}
</mat-radio-group>
