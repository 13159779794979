import { Component, inject } from '@angular/core';
import {AuthService} from '../../../services/authService';
import {PartnersDirectoryService} from '../../../services/partnersDirectory';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';

import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {ICompany} from '../../../services/api.types';

@Component({
    selector: 'oex-save-mode-chooser',
    styleUrls: ['saveModeChooseModal.scss'],
    imports: [
        MatRadioButton,
        NgSelectModule,
        FormsModule,
        MatRadioGroup
    ],
    templateUrl: 'saveModeChooseModal.html'
})
export class SaveModeChooseModalComponent {
  auth = inject(AuthService);
  pd = inject(PartnersDirectoryService);

  mode = '0';
  selectedCompany= -1;
  userCompanies: ICompany[] = [];

  constructor() {
    this.userCompanies = this.auth.getUserCompanies();
    this.selectedCompany = this.userCompanies[0]?.id || -1;
  }
}
