<h3>
  {{ isNew ? (pd.enabled ? 'New product' : 'New application') : ('Edit ') }}
  @if (!isNew) {
    <strong>{{ model.Name }}</strong>
  }
  <!-- Status -->
  <span [class]="'mp-status mp-tile-status-' + getStatus()">{{ getStatusText() }}</span>

  <!-- Share button -->
  @if (!isNew && model && (model.Published || model.ParentSolution)) {
    <oex-share-button
      [link]="'/package/' + (model.Published ? model.NameWithoutSpaces : model.ParentSolution?.NameWithoutSpaces)"
      [name]="model.Published ? model.Name : model.ParentSolution?.Name"
      [desc]="model.Published ? model.Description : model.ParentSolution?.Description"
      [small]="true"
    >
    </oex-share-button>
  }
</h3>

<!-- Breadcrumbs -->
<div class="portal-breadcrumbs">
  <a routerLink="/portal/products">{{ pd.enabled ? 'Products' : 'Applications' }}</a>
  <span>{{ isNew ? (pd.enabled ? 'New product' : 'New application') : model.Name }}</span>
</div>

<div class="cols" [class.hidden]="isPreview">
  <div class="editing-controls">

    <!-- Use github/gitlab readme checkbox template -->
    <ng-template #useGit>
      @if (!pd.enabled) {
        <mat-checkbox class="gitcheck" [(ngModel)]="model.FullDescriptionGitCheck" (change)="isUnsaved = true"
                      name="useGitReadme" data-github-readme>Use GitHub/GitLab README as long description
        </mat-checkbox>
      }
    </ng-template>

    <!-- Github URL -->
    @if (!pd.enabled) {
      <label>GitHub/GitLab URL</label>
      <div class="field full-row mb-small">
        <input #giturl type="text" (ngModelChange)="onGithubChanged(giturl.value)" [(ngModel)]="model.Github"
               (blur)="onGitgubUrlBlur()"
               name="Github" placeholder="https://" (change)="isUnsaved = true" data-gitgub>
        @if (model.previousModel) {
          <oex-diff
            [text1]="model.previousModel.Github"
            [text2]="model.Github"
            mode="char"
          ></oex-diff>
        }
      </div>
      <!-- Use Github description -->
      <ng-container [ngTemplateOutlet]="useGit"></ng-container>
    }

    <!-- Application Name -->
    <div>
      <label>{{ pd.enabled ? 'Product' : 'Application' }} Name*</label>
      <div class="field">
        <input type="text" [(ngModel)]="model.Name" name="Name" (change)="isUnsaved = true" required data-name>
        @if (model.previousModel) {
          <oex-diff
            [text1]="model.previousModel.Name"
            [text2]="model.Name"
            mode="char"
          ></oex-diff>
        }
      </div>
    </div>

    <!-- Category -->
    <div>
      <label>Category*</label>
      <div class="field">
        <ng-select
          [(ngModel)]="model.CategoryID._id"
          bindLabel="Name"
          bindValue="ID"
          [items]="categories"
          [clearable]="false"
          (change)="updateCategoryName()"
          [disabled]="!isOwner"
          required
        ></ng-select>
        @if (model.previousModel) {
          <oex-diff
            class="for-select"
            mode="word"
            [text1]="model.previousModel.CategoryID.Name"
            [text2]="model.CategoryID.Name"
          ></oex-diff>
        }
      </div>
    </div>

    <!-- ISC Technology -->
    <label>InterSystems Technology*</label>
    <div class="field full-row">
      <ng-select
        [(ngModel)]="model.ISCTechnology"
        [multiple]="true"
        [items]="products"
        [clearable]="false"
        [clearSearchOnAdd]="true"
        [closeOnSelect]="false"
        (change)="isUnsaved = true"
        [disabled]="!isOwner"
        required
      ></ng-select>
      @if (model.previousModel) {
        <oex-diff
          class="for-select"
          mode="word"
          [text1]="getTechText(model.previousModel)"
          [text2]="getTechText(model)"
        ></oex-diff>
      }
    </div>

    <!-- Industries -->
    <div>
      <label>Industries*</label>
      <div class="field">
        <ng-select
          [(ngModel)]="model.Industries"
          [multiple]="true"
          bindLabel="Name"
          bindValue="Name"
          [items]="industries"
          [clearable]="false"
          [clearSearchOnAdd]="true"
          [closeOnSelect]="false"
          (change)="isUnsaved = true"
          [disabled]="!isOwner"
          required
        ></ng-select>
        @if (model.previousModel) {
          <oex-diff
            class="for-select"
            mode="word"
            [text1]="getIndText(model.previousModel)"
            [text2]="getIndText(model)"
          ></oex-diff>
        }
      </div>
    </div>

    <!-- Action name -->
    <div>
      <label>Call to Action button*</label>
      <div class="field">
        <ng-select
          [(ngModel)]="model.Action._id"
          bindLabel="Name"
          bindValue="_id"
          [items]="actions"
          [clearable]="false"
          (change)="updateActionName()"
          [disabled]="!isOwner"
          required
        >
          <!-- Label template -->
          <ng-template ng-label-tmp let-item="item">
            {{ item.Name }}
            <span class="action-desc" [ui-tooltip]="item.Description">({{ item.Description }})</span>
          </ng-template>
          <!-- Option template -->
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            {{ item.Name }}
            <span class="action-desc" [ui-tooltip]="item.Description">({{ item.Description }})</span>
          </ng-template>
        </ng-select>

        @if (model.previousModel) {
          <oex-diff
            class="for-select"
            mode="word"
            [text1]="getActionTypeText(model.previousModel)"
            [text2]="getActionTypeText(model)"
          ></oex-diff>
        }
      </div>
    </div>

    <!-- About URL -->
    <div>
      <label>About URL*</label>
      <div class="field">
        <input
          type="text"
          [(ngModel)]="model.ProductURL"
          name="ProductURL"
          placeholder="https://"
          (change)="isUnsaved = true"
          (blur)="onActionUrlBlur()"
          required
          data-action-url
        >
        @if (model.previousModel) {
          <oex-diff
            [text1]="model.previousModel.ProductURL"
            [text2]="model.ProductURL"
            mode="char"
          ></oex-diff>
        }
      </div>
    </div>

    <!-- License URL -->
    <div>
      <label>{{ pd.enabled ? 'License or Terms of use URL' : 'License URL' }}*</label>
      <div class="field">
        <input
          type="text"
          [(ngModel)]="model.Terms"
          name="Terms"
          placeholder="https://"
          (change)="isUnsaved = true"
          (blur)="onLicenseUrlBlur()"
          required
          data-license-url
        >
        @if (model.previousModel) {
          <oex-diff
            [text1]="model.previousModel.Terms"
            [text2]="model.Terms"
            mode="char"
          ></oex-diff>
        }
      </div>
    </div>

    <!-- Tags -->
    <div class="full-row field">
      <label>Tags *</label>
      <!--<input type="text" [(ngModel)]="model.Tag" name="Tag" placeholder="Tag1, Tag2, Tag3" (change)="isUnsaved = true" required data-tags>-->
      <ng-select
        #tagsSelect
        [(ngModel)]="model.Tag"
        [addTag]="true"
        [multiple]="true"
        [items]="tags"
        [clearable]="false"
        [clearSearchOnAdd]="true"
        (change)="isUnsaved = true"
        [disabled]="!isOwner"
        placeholder="Tag1, Tag2, Tag3"
        required>
      </ng-select>
    </div>

    <!-- AI & ML tags -->
    @if (!pd.enabled) {
      <div class="field full-row">
        <label>My application contains </label>
        <mat-checkbox name="AI" (change)="onAIChange($event)" style="margin-right: 20px">AI - Artificial Intelligence
        </mat-checkbox>
        <mat-checkbox name="ML" (change)="onAIChange($event, false)">ML - Machine Learning</mat-checkbox>
      </div>
    }

    <!-- Short description -->
    <label>Short description*</label>
    <div class="full-row field" [class.order-10]="pd.enabled">
            <textarea class="pkg-desc-short" type="text" [(ngModel)]="model.Description"
                      [placeholder]="'Enter a brief summary of your ' + (pd.enabled ? 'product': 'application')"
                      (change)="isUnsaved = true"
                      name="Description" maxlength="64" required data-short-desc></textarea>
      @if (model.previousModel) {
        <oex-diff
          [text1]="model.previousModel.Description"
          [text2]="model.Description"
          mode="word"
          class="textarea"
        ></oex-diff>
      }
    </div>

    <!-- Full description -->
    <div class="full-row">
      <label>Full Description
        <span class="long-desc-tooltip-icon"
              matTooltipClass="multiline-tooltip"
              [matTooltip]="pd.enabled ? 'Please note, that for better results you would want to add in the long description main problems or tasks which your product solves'
                                             : 'Please note, that in long description you should mention:

                      • App\'s goals and functions
                      • How to use the app
                      • If it is open source app - please, provide the installation instructions
                    '"
        ><img src="assets/img/info.svg"></span>
      </label>
      <div class="field mb-small">
        <ui-tui-editor
          #editorDesc
          [(value)]="model.FullDescription"
          class="pkg-desc-long"
          [placeholder]="'Enter a detailed description of your ' + (pd.enabled ? 'product': 'application')"
          [disabled]="!isOwner"
          (change)="onLongDescChange()"
          data-long-desc
        >
        </ui-tui-editor>
        @if (model.previousModel) {
          <oex-diff
            [text2]="model.FullDescription"
            [text1]="model.previousModel.FullDescription"
            [tui]="editorDesc"
            mode="word"
            class="tui-editor"
          ></oex-diff>
        }
      </div>

      <!-- Use Github description -->
      <ng-container [ngTemplateOutlet]="useGit"></ng-container>
    </div>

    <!-- Publish in Package Manager -->
    @if (!pd.enabled) {
      <div class="field full-row">
        <mat-checkbox [(ngModel)]="model.PublishPM" name="PublishPM" (change)="isUnsaved = true" data-zpm>Publish in
          Package Manager (<a
            href="https://docs.openexchange.intersystems.com/apps/ipm/" target="_blank" rel="noopener noreferrer"
            class="mp-link">more details</a>)
        </mat-checkbox>
        @if (model.previousModel) {
          <oex-diff
            class="check"
            [text1]="(model.previousModel.PublishPM === false || model.previousModel.PublishPM === 0) ? 'Not checked' : 'Checked'"
            [text2]="(model.PublishPM  === false || model.PublishPM === 0) ? 'Not checked' : 'Checked'"
            mode="nodiff"
          ></oex-diff>
        }
      </div>
    }

    <!-- Python package -->
    @if (!pd.enabled) {
      <label>Python Package</label>
      <div class="field full-row">
        <input
          type="text"
          [(ngModel)]="model.PythonPackage"
          name="PythonPackage"
          placeholder=""
          (change)="isUnsaved = true"
        >
        @if (model.previousModel) {
          <oex-diff
            [text1]="model.previousModel.PythonPackage"
            [text2]="model.PythonPackage"
            mode="char"
          ></oex-diff>
        }
      </div>
    }

    <!-- Python package -->
    @if (!pd.enabled) {
      <label>Ideas Portal link <img src="assets/img/info.svg"
                                    matTooltip="If you were inspired by an idea from InterSystems Ideas Portal pleas put the link to the respective idea in this field"></label>
      <div class="field full-row">
        <input
          type="text"
          [(ngModel)]="model.IdeasPortalURL"
          name="IdeasPortalURL"
          placeholder="https://ideas.intersystems.com/..."
          (change)="isUnsaved = true"
          (blur)="onIdeasPortalUrlBlur()"
        >
        @if (model.previousModel) {
          <oex-diff
            [text1]="model.previousModel.IdeasPortalURL"
            [text2]="model.IdeasPortalURL"
            mode="char"
          ></oex-diff>
        }
      </div>
    }

    <!-- GitHub sponsor link -->
    @if (!pd.enabled) {
      <div>
        <label>Github sponsorship link <img src="assets/img/info.svg"></label>
        <div class="field">
          <input
            type="text"
            name="GitSponsorUrl"
            placeholder="https://"
            [(ngModel)]="model.SponsorshipURL"
            (change)="isUnsaved = true"
            (blur)="onGitSponsorUrlBlur()"
          >
          @if (model.previousModel) {
            <oex-diff
              [text1]="model.previousModel.SponsorshipURL"
              [text2]="model.SponsorshipURL"
              mode="char"
            ></oex-diff>
          }
        </div>
      </div>
    }

    <!-- Demo URL -->
    <div>
      <label>Demo URL</label>
      <div class="field">
        <input
          type="text"
          [(ngModel)]="model.DemoURL"
          name="DemoURL"
          placeholder="Link to download demonstration application or to the application online"
          (change)="isUnsaved = true"
          (blur)="onDemoUrlBlur()"
          data-demo-url
        >
        @if (model.previousModel) {
          <oex-diff
            [text1]="model.previousModel.DemoURL"
            [text2]="model.DemoURL"
            mode="char"
          ></oex-diff>
        }
      </div>
    </div>

    <!-- Documentation URL -->
    <div>
      <label>Documentation URL</label>
      <div class="field">
        <input
          type="text"
          [(ngModel)]="model.Documentation"
          (change)="isUnsaved = true"
          (blur)="onDocUrlBlur()"
          name="Documentation"
          placeholder="https://"
          data-documentation-url
        >
        @if (model.previousModel) {
          <oex-diff
            [text1]="model.previousModel.Documentation"
            [text2]="model.Documentation"
            mode="char"
          ></oex-diff>
        }
      </div>
    </div>

    <!-- Support URL -->
    <div>
      <label>Support URL</label>
      <div class="field">
        <input
          type="text"
          [(ngModel)]="model.Support"
          (change)="isUnsaved = true"
          (blur)="onSupportUrlBlur()"
          name="Support"
          placeholder="https://"
          data-support-url
        >
        @if (model.previousModel) {
          <oex-diff
            [text1]="model.previousModel.Support"
            [text2]="model.Support"
            mode="char"
          ></oex-diff>
        }
      </div>
    </div>

    <!-- Fill one input space for PD, because GitHub sponsor field is hidden on PD -->
    @if (pd.enabled) {
      <div></div>
    }

    <!-- Community article -->
    <div>
      <label>Community Article URL</label>
      @for (dc of model.DCURLs; track trackByIndex(idx, dc); let idx = $index) {
        <div class="field stacking">
          <input
            type="text"
            [class.with-delete]="idx !== 0"
            [(ngModel)]="model.DCURLs[idx].url"
            name="DCURL"
            placeholder="Link to the related DC article"
            (change)="isUnsaved = true"
            (blur)="onArticleUrlBlur(idx)"
            data-community-article
          >
          @if (idx !== 0) {
            <button class="btn-delete-input" (click)="deleteDCUrl(idx)"><img src="/assets/img/delete-input.svg">
            </button>
          }
          @if (model.previousModel?.DCURLs?.[idx]) {
            <oex-diff
              [text1]="model.previousModel?.DCURLs?.[idx]?.url"
              [text2]="model.DCURLs[idx].url"
              [class.multi]="idx !== 0"
              mode="char"
            ></oex-diff>
          }
        </div>
      }
      <button class="btn-gray btn-add" (click)="addDCUrl()">
        <img src="assets/img/plus.svg">
        <span>Add article links</span>
      </button>
    </div>

    <!-- YouTube URL -->
    <div>
      <label>YouTube URL</label>
      @for (vid of model.VideoURLs; track trackByIndex(idx, vid); let idx = $index) {
        <div class="field stacking">
          <input
            type="text"
            [class.with-delete]="idx !== 0"
            [(ngModel)]="model.VideoURLs[idx].url"
            name="VideoUrl"
            placeholder="https://youtube.com/watch?v=12345678"
            (change)="isUnsaved = true"
            (blur)="onVideoUrlBlur(idx)"
            data-video-url
          >
          @if (idx !== 0) {
            <button class="btn-gray transparent btn-delete-input" (click)="deleteVideo(idx)"><img
              src="/assets/img/delete-input.svg"></button>
          }
          @if (model.previousModel?.VideoURLs?.[idx]) {
            <oex-diff
              [text1]="model.previousModel?.VideoURLs?.[idx]?.url"
              [text2]="model.VideoURLs[idx].url"
              [class.multi]="idx !== 0"
              mode="char"
            ></oex-diff>
          }
        </div>
      }
      <button class="btn-gray btn-add" (click)="addVideoUrl()">
        <img src="assets/img/plus.svg">
        <span>Add video links</span>
      </button>
    </div>

    <!--<div class="spacer full-row" *ngIf="!pd.enabled"></div>-->

    <!-- PD publish -->
    <div class="hide-on-pd full-row">
      @if (isHideOnPD) {
        @if (pd.enabled) {
          <span>
            <img src="/assets/img/attention-icon.svg" class="small">Your application will be published on OpenExchange only, because you selected "{{ model.CategoryID.Name }}" category
          </span>
        }
      } @else {
        @if (pd.enabled) {
          <mat-checkbox [(ngModel)]="model.ShowOnOEX">
            Also publish on Open Exchange
            <div class="ui-icon-info"
                 [ui-tooltip]="'Open Exchange - is a place for developers and where they\nshare and find useful tools for development process.\nIf your product is made for developers needs you may\nalso publish it on Open Exchange.'"></div>
          </mat-checkbox>
        }
      }
      @if (pd.enabled) {
        <div>
          <mat-checkbox [(ngModel)]="model.ShowOnHIH">
            Also publish on Health Innovation Hub
            <div class="ui-icon-info"
                 [ui-tooltip]="'The InterSystems Health Innovation Hub is a platform that\nenables partners to showcase and distribute their healthcare\nsolutions, utilizing advanced InterSystems technology to\nenhance patient care and expand their market reach.'"></div>

          </mat-checkbox>
        </div>
      }
    </div>

  </div>
  <div class="col-logo">
    <label>Logo<img class="info-tip" src="assets/img/info.svg"
                    matTooltip="Supported formats: svg, jpg, jpeg, png. (5Mb maximum)"></label>
    <!-- Logo -->
    <div class="field">
      <oex-img-upload #imgUploader [image]="model.ImageURL" (change)="onLogoUploaderChanged()"></oex-img-upload>

      @if (model.previousModel) {
        <oex-diff
          class="image"
          [isImage]="true"
          [text1]="model.previousModel.ImageURL"
          [text2]="model.ImageURL"
          style="margin-bottom: 34px"
          mode="nodiff"
        ></oex-diff>
      }
    </div>
  </div>
</div>

<!-- Actions  -->
@if (!isPreview && isOwner) {
  <div class="ui-portal-actions">
    <!-- Save button -->
    <button class="btn-primary tablet-50" [class.disabled]="model.NeedApprove" (click)="askForSaveMode()">Save</button>
    <!-- Preview button -->
    <button class="btn-outline tablet-50" (click)="preview()">Preview</button>
    <!-- Cancel approval -->
    @if (model.NeedApprove) {
      <button class="btn-outline tablet-50" (click)="askForCancelApproval()">Cancel approval</button>
    }
    <!-- Publish button -->
    @if (canPublish()) {
      <button class="btn-outline btn-approve" (click)="askForApprovalBeforeSave()">Send for approval</button>
    }
    <!-- Unpublish button -->
    @if (model.Published || model.previousModel?.Published) {
      <button class="btn-outline" (click)="askForUnpublish()">Unpublish</button>
    }
    @if (auth.isAdmin) {
      <button class="btn-gray" (click)="askForDeletion()">
        <img src="assets/img/delete.svg">
        Delete
      </button>
    }
    <!--<div class="btn-blue btn-action" (click)="cancel()">Cancel</div>-->
    <oex-warning
      [isUnsaved]="isUnsaved && !model.NeedApprove"
      [isDraft]="!model.Published && !model.NeedApprove"
      [isSent]="model.NeedApprove"
      [hasParent]="!!model.previousModel && model.previousModel._id !== model._id"
      [isParentPublished]="!!model.previousModel?.Published"
      itemName="application"
    >
    </oex-warning>
  </div>
}

@if (isPreview && previewModel) {
  <div class="preview-container">
    <oex-pkg-screen [package]="previewModel"></oex-pkg-screen>
  </div>
}

<input #uploader type="file" accept="image/*" enctype="multipart/form-data" (change)="startUpload($event)" hidden>
